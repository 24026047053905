import React, { Component } from 'react'
import DisplayItemLinkSettings from './DisplayItemLinkSettings'
import DisplayItemFileSettings from './DisplayItemFileSettings'
import DisplayItemDateSettings from './DisplayItemDateSettings'
import DisplayItemUrlSettings from './DisplayItemUrlSettings'
import DisplayItemAmountSettings from './DisplayItemAmountSettings'
import DisplayItemEmailSettings from './DisplayItemEmailSettings'
import DisplayItemTextSettings from './DisplayItemTextSettings'
import DisplayItemUserSettings from './DisplayItemUserSettings'
import DisplayItemCustomActionButtonSettings from './DisplayItemCustomActionButtonSettings'
import { Alert } from 'react-bootstrap'
import DisplayItemListSettings from './DisplayItemListSettings'
import DisplayItemBlockSettings from './DisplayItemBlockSettings'
import DisplayItemDossierDataSettings from './DisplayItemDossierDataSettings'
import DisplayItemTimeSettings from './DisplayItemTimeSettings'
import DisplayItemTextareaSettings from './DisplayItemTextareaSettings'

class DisplayItemSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            settings: this.props.item.settings ? this.props.item.settings : {},
        }
        this.onChange = this.onChange.bind(this)
    }

    onChange(name, value) {
        let settings = this.state.settings
        settings[name] = value
        this.setState({ settings: settings })
        this.props.onChange(name, value)
    }

    render() {
        let compProps = {
            item: this.props.item,
            kedo: this.props.kedo,
            onChange: this.onChange,
        }
        const item = this.props.item
        if (item.def_dossier_link) {
            return <DisplayItemLinkSettings {...compProps} />
        } else if (
            item.def_dossier_def_field &&
            item.def_dossier_def_field.def_field &&
            item.def_dossier_def_field.def_field.type
        ) {
            let fieldType = item.def_dossier_def_field.def_field.type
            if (fieldType === 'text') {
                return <DisplayItemTextSettings {...compProps} />
            } else if (fieldType === 'textarea') {
                return <DisplayItemTextareaSettings {...compProps} />
            } else if (fieldType === 'customActionButton') {
                return <DisplayItemCustomActionButtonSettings {...compProps} />
            } else if (fieldType === 'file') {
                return <DisplayItemFileSettings {...compProps} />
            } else if (fieldType === 'date') {
                return <DisplayItemDateSettings {...compProps} />
            } else if (fieldType === 'url') {
                return <DisplayItemUrlSettings {...compProps} />
            } else if (fieldType === 'amount') {
                return <DisplayItemAmountSettings {...compProps} />
            } else if (fieldType === 'time') {
                return <DisplayItemTimeSettings {...compProps} />
            } else if (fieldType === 'email') {
                return <DisplayItemEmailSettings {...compProps} />
            } else if (fieldType === 'user') {
                return <DisplayItemUserSettings {...compProps} />
            } else if (fieldType === 'list') {
                return <DisplayItemListSettings {...compProps} />
            }
        } else if (
            item.settings &&
            item.settings.dossier_data &&
            item.settings.dossier_data.length > 0
        ) {
            return <DisplayItemDossierDataSettings {...compProps} />
        } else if (!item.def_dossier_link && !item.def_dossier_def_field) {
            return <DisplayItemBlockSettings {...compProps} />
        }

        return (
            <div>
                <h3>{this.props.kedo.t('Settings')}</h3>
                <Alert variant={'info'}>
                    {this.props.kedo.t('No settings available')}
                </Alert>
            </div>
        )
    }
}

export default DisplayItemSettings
