import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import Select from 'react-select'

class DisplayItemTextSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            validators:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.validators
                    ? this.props.item.settings.validators
                    : [],
            phone_number:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.phone_number
                    ? this.props.item.settings.phone_number
                    : null,
        }
        this.onChangeValidators = this.onChangeValidators.bind(this)
    }

    onChangeValidators(options) {
        let validators = []
        if (options) {
            options.forEach((item) => {
                validators.push(item.value)
            })
        }

        this.setState({ validators: validators })
        this.props.onChange('validators', validators)
    }

    render() {
        const kedo = this.props.kedo
        const validatorOptions = [
            { value: 'BSN', label: kedo.t('BSN') },
            { value: 'IBAN', label: kedo.t('IBAN') },
            { value: 'UniqueValue', label: kedo.t('UniqueValue') },
        ]

        let selected = []
        validatorOptions.forEach((item) => {
            if (
                this.state.validators &&
                Array.isArray(this.state.validators) &&
                this.state.validators.includes(item.value)
            ) {
                selected.push(item)
            }
        })

        return (
            <div>
                <Form.Group>
                    <Form.Label>{this.props.kedo.t('Validators')}</Form.Label>
                    <Select
                        className={'basic-multi-select'}
                        id={`${this.props.item.id}-text-settings`}
                        isMulti
                        options={validatorOptions}
                        placeholder={this.props.kedo.t('Choose an option')}
                        onChange={this.onChangeValidators}
                        value={selected}
                        noOptionsMessage={() =>
                            this.props.kedo.t('No items found')
                        }
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        type={'checkbox'}
                        label={kedo.t('Phone number')}
                        onChange={(e) => {
                            this.setState({ phone_number: e.target.checked })
                            this.props.onChange(
                                'phone_number',
                                e.target.checked
                            )
                        }}
                        name={'phone_number'}
                        checked={this.state.phone_number === true}
                    />
                </Form.Group>
            </div>
        )
    }
}

export default DisplayItemTextSettings
