import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { Form, FormControl } from 'react-bootstrap'
import DOMPurify from 'dompurify'

class TextareaField extends React.Component {
    constructor(props) {
        super(props)

        this.innerRef = React.createRef()

        this.state = {
            value: this.props.value,
            readMore: false,
            lastChanged: null,
        }

        this.doFocus = this.doFocus.bind(this)
        this.onChangeRich = this.onChangeRich.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    getTranslationFromItem(item) {
        if (!item.translations || item.translations.length <= 0) {
            return
        }

        let locale = this.props.kedo.env().getSelectedLocale()
        let trans = item.translations.find(
            (trans) => trans.culture === locale.code
        )

        if (!trans && item.translations.length > 0) {
            trans = item.translations[0]
        }

        return trans
    }

    doFocus() {
        setTimeout(() => this.innerRef.current.focus(), 1)
        window.scrollTo(0, this.innerRef.current.offsetTop)
    }

    getValue() {
        return this.state.value
    }

    onChangeRich(content) {
        let lastChanged = this.state.lastChanged
        if (lastChanged !== null && Date.now() - lastChanged > 30 * 1000) {
            let api = this.props.kedo.api()
            api.checkTokenForExpiryAndRenew()
            lastChanged = Date.now()
        } else if (this.state.lastChanged === null) {
            lastChanged = Date.now()
        }

        this.setState({
            value: content,
            lastChanged: lastChanged,
        })
        if (this.props.changeValue) {
            this.props.changeValue(this.props.item.id, content)
        }
    }

    onChange(event) {
        this.setState({ value: event.target.value })
        if (this.props.changeValue) {
            this.props.changeValue(this.props.item.id, event.target.value)
        }
    }

    isInvalid() {
        if (this.props.errors && this.props.errors.length > 0) {
            return true
        }

        return false
    }

    isRichEdit() {
        return true
    }

    renderEdit() {
        if (this.props?.custom_layout === 'row_input') {
            const trans = this.getTranslationFromItem(this.props.item)
            return (
                <div>
                    <FormControl
                        className={'displayItemField'}
                        onChange={this.onChange}
                        as={'input'}
                        autoFocus={true}
                        style={{ minWidth: '200px' }}
                        placeholder={trans ? trans.name : null}
                        value={this.state.value}
                        onKeyPress={
                            this.props.onKeyPress
                                ? (event) =>
                                      this.props.onKeyPress(
                                          this.props.item,
                                          event
                                      )
                                : null
                        }
                        isInvalid={this.isInvalid()}
                        ref={this.innerRef}
                        maxLength={255}
                    />
                    {this.isInvalid() ? (
                        <div className="invalid-feedback">
                            {this.props.errors}
                        </div>
                    ) : null}
                </div>
            )
        }

        return (
            <div ref={this.innerRef}>
                <div className={`${this.isInvalid() ? 'invalidMCE' : ''}`}>
                    <Editor
                        initialValue={this.isInvalid() ? '' : this.props.value}
                        onEditorChange={this.onChangeRich}
                        init={{
                            branding: false,
                            default_link_target: '_blank',
                            font_formats:
                                'Arial=arial,helvetica,sans-serif; Arial Black=arial black,gadget,sans-serif; Comic Sans=comic sans ms,cursive,sans-serif; Courier New=courier new,courier,monospace; Georgia=georgia,serif; Impact=impact,charcoal,sans-serif; Lucida Sans Unicode=lucida sans unicode,lucida grande,sans-serif; Palatino Linotype=alatino linotype,book antiqua,palatino,serif; Tahoma=tahoma,geneva,sans-serif; Trebuchet=trebuchet,helvetica,sans-serif; Times New Roman =times new roman,times,serif; Verdana=verdana,geneva,sans-serif;',
                            fontsize_formats:
                                '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                            height: 250,
                            body_class: 'tinyMCE-editor',
                            browser_spellcheck: true,
                            contextmenu: false,
                            plugins: [
                                'table',
                                'lists',
                                'link',
                                'autolink',
                                'emoticons',
                                'help',
                            ],
                            menubar: false,
                            toolbar:
                                'undo redo | styleselect | fontselect fontsizeselect bold italic underline strikethrough forecolor backcolor | hr link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | table | emoticons | removeformat  | paste | help',
                            toolbar_mode: 'sliding',
                            paste_data_images: true,
                            setup: (editor) => {
                                editor.on('keyup', (e) => {
                                    const content = editor.getContent({ format: 'text' });
                                    const lastWord = content.split(/\s+/).pop();
                                    const urlPattern = /(https?:\/\/[^\s]+)/g;

                                    if (urlPattern.test(lastWord)) {
                                        const linkifiedContent = content.replace(
                                            urlPattern,
                                            (url) => `<a href="${url}" target="_blank">${url}</a>`
                                        );
                                        editor.setContent(linkifiedContent);
                                    }
                                });
                            },
                        }}
                    />
                </div>
                {this.isInvalid() ? (
                    <span className="fake-feedback">{this.props.errors}</span>
                ) : null}
            </div>
        )
    }

    renderShow() {
        let maxLength = this.props.mode === 'list' ? 160 : 450
        let allowedRules = {
            ALLOWED_TAGS: [
                'b',
                'br',
                'span',
                'table',
                'ol',
                'tbody',
                'li',
                'i',
                'strong',
                'th',
                'tr',
                'td',
                'thead',
                'div',
                'p',
                's',
                'h1',
                'h2',
                'img',
            ],
            ALLOWED_ATTR: ['style', 'border'],
        }
        if (this.props.mode === 'list') {
            allowedRules = {
                ALLOWED_TAGS: [
                    'b',
                    'br',
                    'span',
                    'table',
                    'ol',
                    'tbody',
                    'li',
                    'i',
                    'strong',
                    'th',
                    'tr',
                    'td',
                    'thead',
                    'div',
                    'p',
                    'h1',
                    'h2',
                    'img',
                ],
                ALLOWED_ATTR: [],
            }
        }

        if (!this.props.value || !this.props.value.slice) {
            return null
        }

        let imgArray = []
        let tempDiv = document.createElement('div')
        tempDiv.innerHTML = this.state.value

        let imgElements = tempDiv.querySelectorAll('img')
        Array.from(imgElements).forEach((items) => {
            imgArray.push(items.src)
        })

        if (this.props.value && this.props.value.length <= maxLength) {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            this.props.value,
                            imgArray,
                            allowedRules
                        ),
                    }}
                />
            )
        }

        if (!this.state.readMore) {
            return (
                <div style={{ pointerEvents: 'none' }}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                this.props.value.slice(0, maxLength) + '...',
                                imgArray,
                                allowedRules
                            ),
                        }}
                    />
                    <br />
                    <a
                        style={{ pointerEvents: 'auto' }}
                        className="readMore"
                        onClick={() =>
                            this.setState({ readMore: !this.state.readMore })
                        }
                    >
                        {this.state.readMore
                            ? this.props.kedo.t('Read less')
                            : this.props.kedo.t('Read more')}
                    </a>
                </div>
            )
        }

        return (
            <div style={{ pointerEvents: 'none' }}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            this.props.value,
                            imgArray,
                            allowedRules
                        ),
                    }}
                />
                <a
                    style={{ pointerEvents: 'auto' }}
                    className="readMore"
                    onClick={() =>
                        this.setState({ readMore: !this.state.readMore })
                    }
                >
                    {this.state.readMore
                        ? this.props.kedo.t('Read less')
                        : this.props.kedo.t('Read more')}
                </a>
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== prevProps.value && this.props.mode === 'show') {
            this.setState({ value: this.props.value })
        }
    }

    render() {
        if (
            this.props.mode === 'search' ||
            this.props.mode === 'advanced-search'
        ) {
            return (
                <Form.Control
                    onChange={(event) =>
                        this.props.changeValue(
                            this.props.item.id,
                            event.target.value
                        )
                    }
                    onKeyPress={
                        this.props.onKeyPress
                            ? (event) =>
                                  this.props.onKeyPress(this.props.item, event)
                            : null
                    }
                    type="text"
                    value={this.props.value}
                />
            )
        } else if (
            this.props.mode === 'edit' ||
            this.props.mode === 'show/edit'
        ) {
            return this.renderEdit()
        }
        return this.renderShow()
    }
}

export default TextareaField
