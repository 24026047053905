import React, { Component } from 'react'
import { Form } from 'react-bootstrap'

class DisplayItemTextareaSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            also_search_in:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.also_search_in
                    ? this.props.item.settings.also_search_in
                    : null,
        }
    }

    render() {
        return (
            <div>
                <Form.Group>
                    <Form.Label>Add data field id's to search on</Form.Label>
                    <Form.Control
                        type={'text'}
                        onChange={(e) => {
                            this.setState({ also_search_in: e.target.value })
                            this.props.onChange(
                                'also_search_in',
                                e.target.value
                            )
                        }}
                        value={this.state.also_search_in}
                        name={'also_search_in'}
                        className={'form-control'}
                    />
                </Form.Group>
            </div>
        )
    }
}

export default DisplayItemTextareaSettings
