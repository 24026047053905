import React from 'react'
import { Col, Row, Alert, FormCheck, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCircle } from '@fortawesome/pro-duotone-svg-icons/faCircle'
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes'
import { faHammer } from '@fortawesome/pro-duotone-svg-icons/faHammer'

import LoadingDefault from '../Elements/Loading/LoadingDefault'
import Select from 'react-select'

const multiStyles = {
    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: 'white',
            border: '1px solid #dee2e6',
        }
    },
    multiValueRemove: (styles) => ({
        ...styles,
        backgroundColor: 'white',
        ':hover': {
            color: '#F0647F',
            cursor: 'pointer',
        },
    }),
}
const singleErrorStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            borderColor: '#dc3545',
            boxShadow: state.isFocused
                ? '0 0 0 0.2rem rgba(220, 53, 69, 0.25)'
                : 'transparant',
            ':hover': { borderColor: '#dc3545' },
        }
    },
}

class ListField extends React.Component {
    constructor(props) {
        super(props)

        this.innerRef = React.createRef()

        let selected = this.selectedDefaults()

        this.state = {
            initialLoad: false,
            options:
                this.props.item.def_dossier_def_field.def_field.def_field_lists,
            optionsLoading: false,
            page: 0,
            search: null,
            fastSwitchLoading: false,
            selected: selected,
            selectedOptions: [],
            total: this.props.item.def_dossier_def_field.def_field
                .def_field_lists.length,
            mode: 'show',
            isAdmin: this.props.kedo.isAdminOrEnvironmentAdmin(),
        }

        this.changeSearchOptions = this.changeSearchOptions.bind(this)
        this.doFocus = this.doFocus.bind(this)
        this.getValue = this.getValue.bind(this)
        this.isSingleSelect = this.isSingleSelect.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.renderEdit = this.renderEdit.bind(this)
        this.setSingleSelectByOptionId =
            this.setSingleSelectByOptionId.bind(this)
        this.onFastSwitchChange = this.onFastSwitchChange.bind(this)
    }

    doFocus() {
        if (this.innerRef && this.innerRef.current) {
            setTimeout(() => this.innerRef.current.focus(), 1)
            window.scrollTo(0, this.innerRef.current.offsetTop)
        }
    }

    listOptionsHasVisibilitySettings(item) {
        const searchVisibility =
            this.props.item.def_dossier_def_field.def_field.def_field_lists

        const foundItem = searchVisibility.find(
            (listItem) => listItem.id === item
        )

        if (foundItem) {
            const visibility = foundItem.settings?.roles

            if (visibility > 0) {
                return true
            }
        }
        return false
    }

    selectedDefaults() {
        const selected = []

        if (
            this.props.value &&
            this.props.value.length > 0 &&
            this.props.value.map
        ) {
            this.props.value.map((item) => selected.push(item))
        } else if (this.props.value && this.props.value.length > 0) {
            selected.push(this.props.value)
        } else if (
            this.props.item.view !== 'search' &&
            this.props.mode !== 'advanced-search' &&
            (!this.props.dossier ||
                this.props.mode === 'edit' ||
                this.props.mode === 'show/edit') &&
            (this.props.item.def_dossier_def_field.def_field.def_field_lists.find(
                (item) => item.default === true
            ) ||
                (this.props.item.settings &&
                    this.props.item.settings.default_list_option &&
                    this.props.item.def_dossier_def_field.def_field.def_field_lists.find(
                        (item) =>
                            item.id ===
                            parseInt(
                                this.props.item.settings.default_list_option
                            )
                    )))
        ) {
            let defaultItem =
                this.props.item.def_dossier_def_field.def_field.def_field_lists.find(
                    (item) => item.default === true
                )
            let defaultOption =
                this.props.item.settings &&
                this.props.item.settings.default_list_option &&
                this.props.item.def_dossier_def_field.def_field.def_field_lists.find(
                    (item) =>
                        item.id ===
                        parseInt(this.props.item.settings.default_list_option)
                )

            if (defaultOption) {
                selected.push(defaultOption)
            } else {
                selected.push(defaultItem)
            }
        }
        return selected
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (this.props.mode === 'edit' || this.props.mode === 'show/edit') &&
            this.state.mode === 'show' &&
            prevState.mode !== 'edit'
        ) {
            const selected = this.selectedDefaults()
            this.setState({ mode: 'edit', selected: selected })
        }

        if (
            this.props.mode === 'show' &&
            this.state.mode === 'edit' &&
            prevState.mode !== 'show'
        ) {
            this.setState({ mode: 'show' })
        }
    }

    onCancel() {
        this.setState({ selected: this.props.value })
    }

    getCurrentTranslationItem(listOption, locale) {
        if (listOption.translations && listOption.translations.length > 0) {
            let trans = listOption.translations.find(
                (option) => option.culture === locale.code
            )
            if (trans && trans.text) {
                return trans
            }
        }
        return false
    }

    getCurrentTranslationItemText(listOption, locale) {
        let found = this.getCurrentTranslationItem(listOption, locale)
        if (found) {
            return found.text
        }

        if (listOption.translations && listOption.translations.length > 0) {
            return listOption.translations[0].text
        }

        return listOption.id
    }

    isFastSwitch() {
        if (!this.props.item || !this.props.item.settings) {
            return false
        }
        if (this.props.item.settings.fast_switch === true) {
            return true
        }

        return false
    }

    renderShow() {
        let locale = this.props.kedo.env().getSelectedLocale()

        if (this.isSingleSelect()) {
            if (this.state.selected.length <= 0) {
                return ''
            }

            let trans = this.getCurrentTranslationItemText(
                this.state.selected[0],
                locale
            )

            return (
                <span>
                    {this.state.selected[0].color
                        ? this.getColor(this.state.selected[0].color, trans)
                        : trans}
                </span>
            )
        }

        return (
            <ul>
                {this.state.selected.map((item) => {
                    let trans = this.getCurrentTranslationItemText(item, locale)
                    return (
                        <li key={item.id}>
                            {item.color
                                ? this.getColor(item.color, trans)
                                : trans}
                        </li>
                    )
                })}
            </ul>
        )
    }

    isSingleSelect() {
        let defField = this.props.item.def_dossier_def_field.def_field
        if (defField?.settings?.multiple) {
            return false
        }

        return true
    }

    removeSelectedItem(item) {
        this.setState(
            {
                selected: this.state.selected.filter(
                    (opt) => opt.id !== item.id
                ),
            },
            this.props.recheckConditions
        )
    }

    onFastSwitchChange(item) {
        let isSingleSelect = this.isSingleSelect()
        let curSelected = this.state.selected
        let api = this.props.kedo.api()
        let dossierId = this.props.dossier.id
        this.setState({ fastSwitchLoading: true })
        if (isSingleSelect) {
            //Only need to set value.
            //Todo add payload here.
            let values = []
            if (item && item.id) {
                values.push(item.id)
            }
            api.post(
                api.getContentListEndpoint(
                    dossierId,
                    this.props.item.id,
                    'set'
                ),
                values
            ).then((response) =>
                this.setState({
                    selected: item && item.id ? [item] : [],
                    fastSwitchLoading: false,
                })
            )
        } else {
            let changedItems = []

            if (item) {
                item.map((newItem) => {
                    let existing = curSelected.find(
                        (selItem) => selItem.id === newItem.id
                    )
                    if (!existing) {
                        changedItems.push({ mode: 'new', item: newItem })
                    }
                })
            }

            curSelected.map((curItem) => {
                let isFound =
                    item && item.find((newItem) => curItem.id === newItem.id)
                if (!isFound) {
                    changedItems.push({ mode: 'remove', item: curItem })
                }
            })

            changedItems.map((changedItem, index) => {
                if (changedItem.mode === 'new') {
                    curSelected.push(changedItem.item)
                    api.post(
                        api.getContentListEndpoint(
                            dossierId,
                            this.props.item.id,
                            'add',
                            changedItem.item.id
                        )
                    ).then((response) => {
                        if (changedItems.length === index + 1) {
                            this.setState({
                                selected: curSelected,
                                fastSwitchLoading: false,
                            })
                        }
                    })
                } else {
                    curSelected = curSelected.filter(
                        (curItem) => curItem.id !== changedItem.item.id
                    )
                    api.delete(
                        api.getContentListEndpoint(
                            dossierId,
                            this.props.item.id,
                            'remove',
                            changedItem.item.id
                        )
                    ).then((response) => {
                        if (changedItems.length === index + 1) {
                            this.setState({
                                selected: curSelected.filter(
                                    (curItem) =>
                                        curItem.id !== changedItem.item.id
                                ),
                                fastSwitchLoading: false,
                            })
                        }
                    })
                }
            })
        }
    }

    setSingleSelectByOptionId(itemSelect) {
        let item = null
        if (Array.isArray(itemSelect)) {
            item = itemSelect.length > 1 ? itemSelect[1] : itemSelect[0]
        } else {
            item = itemSelect
        }

        if (!this.isSingleSelect()) {
            return
        }

        if (!item) {
            if (this.props.changeValue) {
                this.props.changeValue(this.props.item.id, [])
            }

            this.setState({ selected: [] })
            return
        }

        let selected = this.state.options.find(
            (opt) => opt.id === parseInt(item.id)
        )
        let selectedItem = {
            color: item.color,
            id: item.id,
            name: item.value,
            label: item.label,
            translations: item.translations,
        }

        //First unset the select option.
        this.setState({ selected: [] }, () => {
            if (this.props.changeValue) {
                this.props.changeValue(this.props.item.id, [selected])
            }

            this.setState(
                {
                    selected: !selected ? [] : [selectedItem],
                },
                () => {
                    if (this.props.shoudListenForConditions) {
                        this.props.recheckConditions()
                    }
                }
            )
        })
    }

    addSelectedItem(item) {
        let select = this.state.selected

        if (this.isSingleSelect() === true && select.length > 0) {
            return
        }

        select = select.filter((opt) => opt.id !== item.id)
        select.push(item)

        if (this.props.changeValue) {
            this.props.changeValue(this.props.item.id, select)
        }

        this.setState(
            {
                selected: select,
            },
            this.props.recheckConditions
        )
    }

    getValue() {
        return this.state.selected.map((item) => item.id)
    }

    searchOption(event) {
        this.setState({ search: event.target.value })
    }

    getColor(backgroundColor, text) {
        return (
            <span>
                <Badge
                    pill
                    style={{
                        backgroundColor: backgroundColor,
                        color: '#fff',
                        pointerEvents: 'none'
                    }}
                >
                    {text}
                </Badge>
            </span>
        )
    }

    getSelectableOptions(options, selects, search) {
        let selectedOptions = options.filter((opt) => {
            let found = selects.find((select) => opt.id === select.id)
            return !found || found.length <= 0
        })

        if (search) {
            let searchUpper = search.toUpperCase()
            let locale = this.props.kedo.env().getSelectedLocale()
            return selectedOptions.filter((opt) => {
                let trans = this.getCurrentTranslationItem(opt, locale)

                if (!trans) {
                    return false
                }

                return trans.text.toUpperCase().indexOf(searchUpper) > -1
            })
        }

        return selectedOptions
    }

    isInvalid() {
        if (this.props.errors && this.props.errors.length > 0) {
            return true
        }

        return false
    }

    renderFastSwitch() {
        let locale = this.props.kedo.env().getSelectedLocale()
        let roles = this.props.kedo.env().getCurrentEnvironmentRoles()

        let selectOptions = []
        this.state.options.forEach((item) => {
            //Hide options
            if (item?.settings?.roles.length > 0) {
                if (
                    roles?.filter((roleItem) =>
                        item?.settings?.roles.includes(roleItem.id)
                    ).length > 0 &&
                    this.state.isAdmin !== true
                ) {
                    return
                }
            }

            let iconContent = ''
            if (
                this.state.isAdmin &&
                item.settings &&
                item.settings.roles &&
                item.settings.roles.length > 0
            ) {
                iconContent = (
                    <FontAwesomeIcon icon={faHammer} className={'ml-2'} />
                )
            }

            let trans = this.getCurrentTranslationItemText(item, locale)

            selectOptions.push({
                id: item.id,
                value: this.getCurrentTranslationItemText(item, locale),
                label: (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {item.color ? this.getColor(item.color, trans) : trans}
                        {iconContent}
                    </div>
                ),
                color: item.color,
                translations: item.translations,
            })
        })

        let currentItem = []

        if (this.state.selected) {
            this.state.selected.forEach((item) => {
                let iconContent = ''
                var isVisibilityValid = this.listOptionsHasVisibilitySettings(
                    item.id
                )

                if (this.state.isAdmin && isVisibilityValid == true) {
                    iconContent = (
                        <FontAwesomeIcon icon={faHammer} className={'ml-2'} />
                    )
                }

                let trans = this.getCurrentTranslationItemText(item, locale)

                currentItem.push({
                    id: item.id,
                    value: this.getCurrentTranslationItemText(item, locale),
                    color: item.color,
                    label: (
                        <div className={'select-input'}>
                            {item.color
                                ? this.getColor(item.color, trans)
                                : trans}
                            {iconContent}
                        </div>
                    ),
                })
            })
        }

        return (
            <div ref={this.innerRef}>
                {this.state.fastSwitchLoading ? (
                    <LoadingDefault />
                ) : (
                    <Select
                        isMulti={this.isSingleSelect() === false}
                        isClearable={this.isSingleSelect() === true}
                        className={'select-style'}
                        value={currentItem}
                        name={'Single Select Listfield'}
                        noOptionsMessage={() =>
                            this.props.kedo.t('No items found')
                        }
                        onChange={(item) =>
                            this.onFastSwitchChange(item ? item : [])
                        }
                        options={selectOptions}
                        isInvalid={this.isInvalid()}
                        placeholder={this.props.kedo.t('Search') + '...'}
                        styles={this.isInvalid() ? singleErrorStyles : ''}
                    />
                )}
                {this.isInvalid() ? (
                    <span className={'fake-feedback'}>{this.props.errors}</span>
                ) : null}
            </div>
        )
    }

    renderList() {
        if (this.isFastSwitch()) {
            return this.renderFastSwitch()
        }

        let locale = this.props.kedo.env().getSelectedLocale()

        return (
            <ul className={'list-group list-group-flush'}>
                {this.state.selected.map((item) => {
                    let trans = this.getCurrentTranslationItemText(item, locale)
                    return (
                        <li
                            className={'list-group-item'}
                            style={{ backgroundColor: 'transparent' }}
                            key={item.id}
                        >
                            {item.color
                                ? this.getColor(item.color, trans, null)
                                : trans}
                        </li>
                    )
                })}
            </ul>
        )
    }

    renderEdit() {
        if (this.state.optionsLoading) {
            return <LoadingDefault />
        }

        let roles = this.props.kedo.env().getCurrentEnvironmentRoles()
        let locale = this.props.kedo.env().getSelectedLocale()
        let selectionFull =
            this.isSingleSelect() === true && this.state.selected.length > 0

        if (this.isSingleSelect()) {
            let selectOptions = []
            this.state.options.forEach((item) => {
                let isDisabled = false
                //Hide options
                if (item?.settings?.roles.length > 0) {
                    if (
                        roles?.filter((roleItem) =>
                            item?.settings?.roles.includes(roleItem.id)
                        ).length > 0 &&
                        this.state.isAdmin !== true
                    ) {
                        isDisabled = true
                        return
                    }
                }

                selectOptions.push({
                    id: item.id,
                    value: this.getCurrentTranslationItemText(item, locale),
                    label: (
                        <div>
                            {item.color
                                ? this.getColor(
                                      item.color,
                                      this.getCurrentTranslationItemText(
                                          item,
                                          locale
                                      )
                                  )
                                : this.getCurrentTranslationItemText(
                                      item,
                                      locale
                                  )}
                        </div>
                    ),
                    color: item.color,
                    translations: item.translations,
                })
            })

            let currentItem = []
            if (this.state.selected) {
                this.state.selected.forEach((item) => {
                    currentItem.push({
                        id: item.id,
                        value: this.getCurrentTranslationItemText(item, locale),
                        color: item.color,
                        label: (
                            <div className={'select-input'}>
                                {item.color
                                    ? this.getColor(
                                          item.color,
                                          this.getCurrentTranslationItemText(
                                              item,
                                              locale
                                          )
                                      )
                                    : this.getCurrentTranslationItemText(
                                          item,
                                          locale
                                      )}
                            </div>
                        ),
                    })
                })
            }
            const colourStyles = {
                control: (styles) => ({
                    ...styles,
                    backgroundColor: 'white',
                }),
                option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                ) => {
                    return {
                        ...styles,
                        backgroundColor: isDisabled
                            ? undefined
                            : isSelected | isFocused
                            ? '#0095ff1a'
                            : undefined,
                        color: isDisabled ? '#ccc' : 'black',
                        cursor: isDisabled ? 'not-allowed' : 'default',

                        ':active': {
                            ...styles[':active'],
                        },
                    }
                },
                multiValue: (styles, { data }) => {
                    return {
                        ...styles,
                        backgroundColor: 'none',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }
                },
                multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    cursor: 'pointer',
                    ':hover': {
                        backgroundColor: '#DCECF7',
                    },
                }),
                ...(this.isInvalid() ? singleErrorStyles : ''),
            }

            return (
                <div ref={this.innerRef}>
                    {this.props.item.settings?.checkbox_view ? (
                        <Row>
                            <Col
                                style={{
                                    display: 'flex',
                                    flexDirection: this.props.item?.settings
                                        ?.checkbox_view_horizontal
                                        ? 'row'
                                        : 'column',
                                    flexWrap: 'wrap',
                                    alignItems: this.props.item?.settings
                                        ?.checkbox_view_horizontal
                                        ? 'center'
                                        : 'flex-start',
                                    gap: '3px',
                                }}
                            >
                                {this.state.optionsLoading ? (
                                    <li className={'list-group-item'}>
                                        <LoadingDefault />
                                    </li>
                                ) : null}
                                {selectOptions.map((item) => (
                                    <span
                                        key={item.id}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '3px',
                                        }}
                                    >
                                        <input
                                            style={{
                                                margin: '0.2rem',
                                            }}
                                            checked={
                                                this.state.selected?.[0]?.id ==
                                                item.id
                                            }
                                            id={item.id.toString()}
                                            type="radio"
                                            name={
                                                this.props.item
                                                    .def_dossier_def_field.id
                                            }
                                            onChange={(e) => {
                                                this.setSingleSelectByOptionId(
                                                    item
                                                )
                                            }}
                                        />
                                        <label
                                            onClick={(e) => {
                                                this.setSingleSelectByOptionId(
                                                    item
                                                )
                                            }}
                                            style={{ margin: 0 }}
                                        >
                                            {item.color
                                                ? this.getColor(
                                                      item.color,
                                                      item.value
                                                  )
                                                : item.value}
                                        </label>
                                    </span>
                                ))}
                            </Col>
                        </Row>
                    ) : (
                        <Select
                            isMulti
                            isClearable={false}
                            isSearchable={true}
                            className={'select-style'}
                            isOptionDisabled={(option) => option.disabled}
                            value={currentItem}
                            name={'Single Select Listfield'}
                            noOptionsMessage={() =>
                                this.props.kedo.t('No items found')
                            }
                            onChange={(item) =>
                                this.setSingleSelectByOptionId(item)
                            }
                            options={selectOptions}
                            isInvalid={this.isInvalid()}
                            placeholder={this.props.kedo.t('Search') + '...'}
                            styles={colourStyles}
                        />
                    )}

                    {this.isInvalid() ? (
                        <span className={'fake-feedback'}>
                            {this.props.errors}
                        </span>
                    ) : null}
                </div>
            )
        }
        return (
            <>
                {this.props.item.settings &&
                this.props.item.settings.checkbox_view ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: this.props.item?.settings
                                ?.checkbox_view_horizontal
                                ? 'row'
                                : 'column',
                            flexWrap: 'wrap',
                            alignItems: this.props.item?.settings
                                ?.checkbox_view_horizontal
                                ? 'center'
                                : 'flex-start',
                            gap: '3px',
                        }}
                    >
                        {this.state.optionsLoading ? (
                            <li className={'list-group-item'}>
                                <LoadingDefault />
                            </li>
                        ) : null}
                        {this.state.options.map((item) => {
                            //Hide options
                            if (item?.settings?.roles.length > 0) {
                                if (
                                    roles?.filter((roleItem) =>
                                        item?.settings?.roles.includes(
                                            roleItem.id
                                        )
                                    ).length > 0 &&
                                    this.state.isAdmin !== true &&
                                    !this.state.selected.find(
                                        (select) => select.id == item.id
                                    )
                                ) {
                                    return
                                }
                            }

                            let trans = this.getCurrentTranslationItemText(
                                item,
                                locale
                            )

                            return (
                                <span
                                    key={item.id}
                                    style={{
                                        display: 'flex',
                                        gap: '3px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <input
                                        style={{
                                            width: '16px',
                                            height: '16px',
                                            margin: '0.2rem',
                                        }}
                                        checked={this.state.selected.find(
                                            (select) => select.id == item.id
                                        )}
                                        id={item.id.toString()}
                                        type="checkbox"
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.addSelectedItem(item)
                                            } else {
                                                this.removeSelectedItem(item)
                                            }
                                        }}
                                    />
                                    <label
                                        htmlFor={item.id.toString()}
                                        style={{ cursor: 'pointer', margin: 0 }}
                                    >
                                        {item.color
                                            ? this.getColor(item.color, trans)
                                            : trans}
                                    </label>
                                </span>
                            )
                        })}
                    </div>
                ) : (
                    <>
                        {this.isInvalid() ? (
                            <Alert variant={'danger'}>
                                {this.props.errors}
                            </Alert>
                        ) : null}
                        <div className="input-group">
                            <input
                                readOnly={
                                    this.state.optionsLoading || selectionFull
                                }
                                aria-describedby="button-addon4"
                                className={'form-control'}
                                type={'text'}
                                value={
                                    this.state.search ? this.state.search : ''
                                }
                                onChange={(event) => this.searchOption(event)}
                                placeholder={
                                    selectionFull
                                        ? this.props.kedo.t(
                                              'Maximum number of items selected'
                                          )
                                        : this.props.kedo.t('Search an option')
                                }
                            />

                            {this.state.search ? (
                                <div
                                    className="input-group-append"
                                    id="button-addon4"
                                >
                                    <button
                                        onClick={() => {
                                            this.setState({ search: null })
                                        }}
                                        className="btn btn-outline-secondary"
                                        type="button"
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                </div>
                            ) : null}
                        </div>
                        <div className={'list-group list-group-flush'}>
                            {this.state.optionsLoading ? (
                                <li className={'list-group-item'}>
                                    <LoadingDefault />
                                </li>
                            ) : null}

                            {this.getSelectableOptions(
                                this.state.options,
                                this.state.selected,
                                this.state.search
                            ).map((item) => {
                                //Hide options
                                if (item?.settings?.roles.length > 0) {
                                    if (
                                        roles?.filter((roleItem) =>
                                            item?.settings?.roles.includes(
                                                roleItem.id
                                            )
                                        ).length > 0 &&
                                        this.state.isAdmin !== true
                                    ) {
                                        return
                                    }
                                }

                                let trans = this.getCurrentTranslationItemText(
                                    item,
                                    locale
                                )

                                return (
                                    <button
                                        key={item.id}
                                        onClick={() =>
                                            this.addSelectedItem(item)
                                        }
                                        className={
                                            'list-group-item list-group-item-action'
                                        }
                                    >
                                        {item.color
                                            ? this.getColor(item.color, trans)
                                            : trans}
                                    </button>
                                )
                            })}
                        </div>
                        <hr />
                        <strong>{this.props.kedo.t('Selected')}</strong>
                        <ul className={'list-group'}>
                            {this.state.selected.map((item) => {
                                let trans = this.getCurrentTranslationItemText(
                                    item,
                                    locale
                                )
                                return (
                                    <li
                                        key={`list-item-${item.id}`}
                                        className={'list-group-item'}
                                    >
                                        {item.color
                                            ? this.getColor(item.color, trans)
                                            : trans}
                                        <button
                                            className={'btn btn-xs float-right'}
                                            onClick={() =>
                                                this.removeSelectedItem(item)
                                            }
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    </>
                )}
            </>
        )
    }

    changeSearchOptions(event) {
        let options = []

        if (!event || !event.map) {
            if (this.props.changeValue) {
                this.props.changeValue(this.props.item.id, [])
            }

            this.setState({ selected: [] })
            return
        }

        event.map((item) => options.push({ id: item.id }))

        if (this.props.changeValue) {
            this.props.changeValue(this.props.item.id, options)
        }

        this.setState({ selected: event })
    }

    renderSearch() {
        let locale = this.props.kedo.env().getSelectedLocale()
        const newSelected = []
        const searchoptions = []

        this.state.options.map((item) =>
            searchoptions.push({
                id: item.id,
                value: this.getCurrentTranslationItemText(item, locale),
                label: (
                    <div>
                        {item.color ? (
                            <span>
                                <FontAwesomeIcon
                                    style={{ color: item.color }}
                                    icon={faCircle}
                                />
                                &nbsp;{' '}
                            </span>
                        ) : null}
                        {this.getCurrentTranslationItemText(item, locale)}
                    </div>
                ),
                color: item.color,
                translations: item.translations,
            })
        )

        this.state.selected.forEach((selected) => {
            const found = searchoptions.find((item) => item.id === selected.id)

            if (found) {
                newSelected.push(found)
            }
        })

        return (
            <Select
                className={'basic-single'}
                id={`${this.props.item.id}-search`}
                isClearable
                isSearchable={true}
                isMulti
                onChange={this.changeSearchOptions}
                options={searchoptions}
                placeholder={this.props.kedo.t('Search') + '...'}
                styles={multiStyles}
                value={newSelected}
                noOptionsMessage={() => this.props.kedo.t('No items found')}
            />
        )
    }

    render() {
        if (this.props.mode === 'edit' || this.props.mode === 'show/edit') {
            return this.renderEdit()
        } else if (this.props.mode === 'list') {
            return this.renderList()
        } else if (
            this.props.mode === 'search' ||
            this.props.mode === 'advanced-search'
        ) {
            return this.renderSearch()
        }

        return this.renderShow()
    }
}

export default ListField
