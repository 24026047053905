import * as React from 'react'
import { useSocketService } from '../../context/SocketServiceContext'
import { default as ReactAvatar } from 'react-avatar'
import { createRef, RefObject, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-duotone-svg-icons/faCircle'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('../../images/xlab-logo.jpg')

import Kedo from '../../Kedo.js'

interface IAvatarOptions {
    togglePresence?: boolean
    size?: any
    showPresence?: boolean
}

type Props = {
    kedo: Kedo
    user: any
    props: any
    onClick?: any
    options?: IAvatarOptions
}

const Avatar = ({ kedo, user, props, onClick, options }: Props) => {
    const socketService = useSocketService()
    const [status, setStatus] = useState('')
    const [toggle, setToggle] = useState(false)
    const [avatar, setAvatar] = useState('')

    useEffect(() => {
        //Add check for privacy if user does not to show online
        if (options?.togglePresence) {
            kedo.api()
                .get(kedo.api().getUserProfileEndpoint('me'))
                .then((result) => {
                    if (
                        result.data &&
                        result.data.private_settings &&
                        result.data.private_settings.presence
                    ) {
                        socketService.user
                            .setPresence(result.data.private_settings.presence)
                            .then((resultPresence) => {
                                setStatus(resultPresence)
                                setToggle(false)
                            })
                    }
                })
                .catch((errResponse) => console.log(errResponse))
        }
    }, [])

    useEffect(() => {
        //Add check for privacy if user does not to show online
        setStatus(socketService.user.getPresence(user.userId))
    }, [socketService.user.getPresence(user.userId)])

    const handleOnClick = (e) => {
        if (e && e.stopPropagation) e.stopPropagation()

        if (!onClick && !options.togglePresence) {
            props.history.push('/user/' + user.userId)
        } else if (!onClick && options.togglePresence) {
            setToggle(!toggle)
        } else if (onClick) {
            onClick()
        }
    }

    const setPresence = (e, presence) => {
        if (e && e.stopPropagation) e.stopPropagation()

        const userData = {
            private_settings: { presence: presence },
        }

        kedo.api()
            .patch(kedo.api().getUserProfileEndpoint('me'), userData)
            .then(() => {
                socketService.user
                    .setPresence(presence)
                    .then((resultPresence) => {
                        setStatus(resultPresence)
                        setToggle(false)
                    })
            })
            .catch((errResponse) => console.log(errResponse))
    }

    const presence = (presence) => {
        switch (presence) {
            case 'online':
                return 'green'
            case 'busy':
                return 'red'
            case 'away':
                return 'orange'

            default:
                return 'unset'
        }
    }

    const fetchAvatar = () => {
        kedo.api()
            .getCached(kedo.api().getUserEndpoint(user.userId))
            .then((response) => {
                setAvatar(
                    response.data && response.data.avatar?.length > 0
                        ? kedo
                              .api()
                              .getUserAvatarEndpoint(
                                  user.userId,
                                  response.data.avatar
                              )
                        : null
                )
            })
    }

    useEffect(() => {
        fetchAvatar()
    }, [user])

    return (
        <>
            <div className={'avatar-online'} onClick={handleOnClick}>
                <ReactAvatar
                    name={`Img avatar ${user.username}`}
                    round={true}
                    value="40%"
                    size={options?.size}
                    src={avatar || (!user?.username ? logo : null)}
                />

                {options?.showPresence ? (
                    kedo.env() && kedo.env().hasEnviroment() ? (
                        <FontAwesomeIcon
                            style={{
                                color: presence(status),
                                top:
                                    options && options.size <= 26
                                        ? '16px'
                                        : '22px',
                                left:
                                    options && options.size <= 26
                                        ? '16px'
                                        : '22px',
                            }}
                            icon={faCircle}
                        />
                    ) : null
                ) : null}
            </div>
            {options?.togglePresence && toggle ? (
                <div
                    style={{ display: 'block', fontSize: '14px' }}
                    className="dropdown-menu"
                >
                    <a
                        onClick={(e) => setPresence(e, 'online')}
                        className="dropdown-item"
                    >
                        <FontAwesomeIcon
                            style={{ color: 'green' }}
                            icon={faCircle}
                        />{' '}
                        Online
                    </a>
                    <a
                        onClick={(e) => setPresence(e, 'away')}
                        className="dropdown-item"
                    >
                        <FontAwesomeIcon
                            style={{ color: 'orange' }}
                            icon={faCircle}
                        />{' '}
                        Away
                    </a>
                    <a
                        onClick={(e) => setPresence(e, 'busy')}
                        className="dropdown-item"
                    >
                        <FontAwesomeIcon
                            style={{ color: 'red' }}
                            icon={faCircle}
                        />{' '}
                        Busy
                    </a>
                    <div className="dropdown-divider" />
                    <a
                        onClick={(e) => setPresence(e, 'invisible')}
                        className="dropdown-item"
                    >
                        <FontAwesomeIcon icon={faCircle} /> Invisible
                    </a>
                </div>
            ) : null}
        </>
    )
}

Avatar.defaultProps = {
    options: {
        size: 35,
        showPresence: true,
    },
}

export default Avatar
